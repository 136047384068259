<template>
  <v-container>
    <v-tabs v-model="tab" grow show-arrows>
      <v-tab v-for="(tab, value) in tabs" :value="value" :disabled="tab.disabled" nuxt="true" :to="`/alerts/${value}`" :class="{ 'selected-tab': tabIsSelected(value), 'unselected-tab': !tabIsSelected(value) }">
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <slot />
  </v-container>
</template>

<script setup lang="ts">
  const tabs: { [key: string]: { title: string; disabled: boolean } } = {
    overall: { title: "Overall", disabled: false },
    environment: { title: "Environmental", disabled: false },
    energy: { title: "EMS", disabled: false },
    assets: { title: "Security", disabled: false },
  };

  const route = useRoute();
  const tab = ref(route.path);

  const tabIsSelected = (value: any) => {
    return tab.value === value;
  };
</script>

<style scoped>
  .v-tabs,
  .v-tab {
    font-family: "Noto Sans", sans-serif;
  }
  .selected-tab {
    color: #001a21;
    font-weight: 700;
  }

  .unselected-tab {
    color: #808b95;
  }
</style>

// GEF factor - 0.758 box
